<template>
  <div>
     <el-card>
      <search-form ref="searchForm" :stretchVisible="true" :topVisible="true" :tabData="tabData" labelWidth="120px" :searchFormData="searchFormData" @search="search" @tabChange="tabChange">
         <template #topBtn>
          <el-button type="primary" size="small" @click="getSalesReport">{{$t('button.salesReport')}}</el-button>
          <el-button type="primary" size="small" @click="getFeeSet">{{$t('button.freightList')}}</el-button>
          <el-button type="primary" size="small" @click="handleExport">{{$t('button.orderExport')}}</el-button>
          <div class="customAmount"><span>{{`${$t('form.order.salesAmount')}: ${total_sales || 0}`}}</span></div>
          <div class="customAmount"><span>{{`${$t('form.order.costAmount')}: ${total_cost || 0}`}}</span></div>
        </template>
      </search-form>
      <create-table @forwardToPage="forwardToPage" @handleNewTab="handleNewTab($event, 'ordersManagerUserOrdersDetail')" :operationColWidth="150" :autoWidth="true" :operateVisible="operateVisible" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" @selectionChange="selectionChange" :topButtonVisible="true" :tableData="tableData" :tableDataItem="tableDataItem" @operate="handleClick" :pageVisible="true" :pageData="pageData" :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort">
        <template #topButton>
          <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
          <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
        </template>
        <template #rightBtn="scope">
          <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index"  :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
          <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </create-table>
    </el-card>
    <router-view/>
    <dialogEffect v-if="dialogVisible" :opereteDate="false" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import { getDineinOrderSetup, getDineinOrderQueryList, getDineinOrderExport, getDineinOrderSalesReport, getDineinOrderFeeSet } from '@/services/order'
// import _ from 'lodash'
// import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
export default {
  name: 'managerDineinOrders',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      total_cost: 0,
      total_sales: 0
    }
  },
  watch: {
    // 'pageData.page': {
    //   handler () {
    //     this.search()
    //   }
    // },
    // 'pageData.per_page': {
    //   handler () {
    //     this.search()
    //   }
    // },
    $route (to, from) {
      // console.log(from)
      if (from.name === 'managerUserOrderView') {
        this.$nextTick(() => {
          this.search()
          this.$refs.createTable.$refs.table.doLayout()
        })
      }
    }
  },
  created () {
    this.queryData(getDineinOrderSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getDineinOrderSetup)
    this.isActive = true
  },
  methods: {
    test1 () {
      console.log('1111')
    },
    test2 () {
      console.log('2222')
    },
    test3 () {
      console.log('3333')
    },
    async getSalesReport () {
      // this.$router.push({
      //   name: 'downloadCenter',
      //   params: { data: [] }
      // })
      this.$refs.searchForm.commonOperate(getDineinOrderSalesReport, (data) => {
        this.$router.push({
          name: 'userOrdersDownloadCenter',
          params: { data }
        })
      }, this)
    },
    async getFeeSet () {
      this.$refs.searchForm.commonOperate(getDineinOrderFeeSet, (data) => {
        this.$router.push({
          name: 'userOrdersDownloadCenter',
          params: { data }
        })
      }, this)
    },
    // 导出数据接口
    handleExport () {
      this.$refs.searchForm.commonOperate(getDineinOrderExport, (data) => {
        this.$router.push({
          name: 'userOrdersDownloadCenter',
          params: { data }
        })
      }, this)
    },
    // 点击操作按钮
    async handleClick (type, data) {
      // this.$store.state.app.loading = this.$loading(loading)
      // let obj = {}
      // if (type?.action === 'view') {
      //   const res = await getOrderDetails(data.id.value)
      //   if (res.status === 200) {
      //     obj = res.data.shop_payment
      //   }
      // }
      // this.$store.state.app.loading.close()
      // this.$message({
      //   type: 'success',
      //   message: this.$t('message.loadSuccess')
      // })
      // sessionStorage.setItem('userOrderdataOperate', JSON.stringify({ id: data && data.id ? data.id : undefined, type: type.action, obj, data }))
      this.$router.push({
        name: 'ordersManagerDineinOrdersDetail',
        params: {
          id: data.id.value
        }
      })
    },
    // handleNewTab (data, name) {
    //   const newpage = this.$router.resolve({
    //     name: 'ordersManagerUserOrdersDetail',
    //     params: {
    //       id: data.id.value
    //     }
    //   })
    //   window.open(newpage.href, '_blank')
    // },
    // // 操作已选项
    // chooseDropItem (val) {
    //   const data = this.dropDataItem.filter(item => item.name === val)
    //   console.log(data)
    //   const ids = []
    //   this.selectionData.forEach(item => {
    //     ids.push(item.id.value)
    //   })
    //   Utils.custOperate(data[0], this, { ids })
    // },
    // // tab切换
    // tabChange (val) {
    //   const index = this.dropDataItem.findIndex(item => item.value === this.tabData[val].value)
    //   const data = _.cloneDeep(this.deepDropItem)
    //   if (index !== -1) {
    //     data.splice(index, 1)
    //     this.dropDataItem = data
    //   } else {
    //     this.dropDataItem = data
    //   }
    // },
    forwardToPage (data, name) {
      if (data.model_name && data.model_name === 'host') {
        this.$router.push({
          name: 'buddyManagerBuddyDetail',
          params: {
            id: data.id
          }
        })
      }
      if (data.model_name && data.model_name === 'shop') {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
      if (data.model_name && data.model_name === 'user') {
        this.$router.push({
          name: 'memberDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getDineinOrderQueryList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          this.total_cost = res.data.total_cost
          this.total_sales = res.data.total_sales
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scope>
  .customAmount {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    font-size: 14px;
    color: #303133;
    font-weight: 500;
  }
</style>
